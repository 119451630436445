<template>
    <v-col>
        <v-row no-gutters>
            <v-col class="col-12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters>
                            <v-col
                                :class="showChemical && isChemicalFromRegistry ? 'col-md-6 pr-0 pr-md-4' : 'pr-0 pr-md-4'">
                                <v-combobox
                                    :prepend-icon="requisiteIcon"
                                    item-text="name"
                                    :item-value="(it) => it.name+it.amount+it.unit+it.note"
                                    :items="requisites"
                                    v-model="requisiteModel"
                                    ref="requisiteModel"
                                    :label="requisitesTypeNameLabel"
                                    :attach="$vuetify.breakpoint.smAndDown"
                                    :error-messages="requisiteError"
                                    auto-select-first
                                    @update:search-input=customSortRequisites
                                    v-on:focus="scroll"
                                    v-on:click:append="() => provideCloseDropdown(this.$refs.requisiteModel)"
                                    @keydown.native="checkIfShowRequisiteReminder"
                                    data-cy="jobevent-requisite-name-input"
                                >
                                    <template v-slot:no-data>
                                        <v-col class="mx-4" v-if="showAddRequisiteReminder">
                                            Sisestage {{ requisitesTypeNames[1] }} nimi või ...
                                        </v-col>
                                    </template>
                                    <template #item="{item}">
                                        <v-list-item-content>
                                            <v-row no-gutters class="d-flex flex-nowrap">
                                                <v-col class="col-auto align-self-center">
                                                    <v-icon class="mr-2">{{ requisiteIconByType(item.type) }}</v-icon>
                                                </v-col>
                                                <v-col class="align-self-center">
                                                    <v-list-item-title>
                                                        {{ item.name }}
                                                        <span style="color: #575757; font-weight: 300">{{ requisiteAmountAndUnitValue(item) }}</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-if="requisiteSubtypeVariant(item)" style="overflow: auto; text-overflow: initial; white-space: initial;">
                                                        {{ requisiteSubtypeVariant(item) }}
                                                    </v-list-item-subtitle>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </template>
                                    <template v-slot:append-item>
                                        <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                                            <v-btn
                                                color="primary"
                                                class="mb-2"
                                                block
                                                data-cy="jobevent-requisite-add-new"
                                                @click.stop="addNewRequisite">
                                                <v-icon>add</v-icon>
                                                Lisa {{ requisitesTypeNames[0] }}
                                            </v-btn>
                                        </v-row>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <div v-if="$vuetify.breakpoint.smAndDown && showRemoveButton"
                                 class="ml-1 ml-sm-4 ml-md-4 align-self-center mt-n2 mr-2 pr-sm-0"
                                 style="width: 40px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <div class="d-inline-block" v-on="on">
                                            <delete-button title="Kustuta tarvik"
                                                           v-on="on"
                                                           :clickAction="removeRequisite"
                                                           data-cy="requisite-delete-button"/>
                                        </div>
                                    </template>
                                    <span>{{ "Eemalda tarvik" }}</span>
                                </v-tooltip>
                            </div>
                            <v-col v-if="showChemical && !$vuetify.breakpoint.smAndDown" class="pr-0 pr-md-4">
                                <v-select
                                    :items="chemicalSubtypeVariants"
                                    item-text="label"
                                    item-value="value"
                                    v-model="subtypeVariant"
                                    :prepend-icon="mdiSelectGroup()"
                                    :error-messages="subtypeVariantError"
                                    label="Kemikaali tüüp"
                                    data-cy="jobevent-requisite-subtype-variant-selection"
                                ></v-select>
                            </v-col>
                            <v-col v-if="showFertilizer && !$vuetify.breakpoint.smAndDown" class="pr-0 pr-md-4">
                                <v-row no-gutters class="justify-center">
                                        <v-radio-group v-model="subtype" row :error-messages="subTypeError">
                                            <v-radio label="Tavaline väetis" :value="FERTILIZER_TYPE.FERTILIZER"></v-radio>
                                            <v-radio label="Lubiväetis" :value="FERTILIZER_TYPE.FERTILIZER_LIMESTONE"></v-radio>
                                        </v-radio-group>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <div v-if="!$vuetify.breakpoint.smAndDown && showRemoveButton" class="ml-0 align-self-center mt-n2"
                         style="width: 40px">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <div class="d-inline-block" v-on="on">
                                    <delete-button title="Kustuta tarvik"
                                                   v-on="on"
                                                   :clickAction="removeRequisite"
                                                   data-cy="requisite-delete-button"/>
                                </div>
                            </template>
                            <span>{{ "Eemalda tarvik" }}</span>
                        </v-tooltip>
                    </div>
                </v-row>
            </v-col>
            <v-col v-if="showChemical && $vuetify.breakpoint.smAndDown" class="col-12 pr-0">
                <v-select
                    :items="chemicalSubtypeVariants"
                    item-text="label"
                    item-value="value"
                    v-model="subtypeVariant"
                    :prepend-icon="mdiSelectGroup()"
                    :error-messages="subtypeVariantError"
                    label="Kemikaali tüüp"
                    data-cy="jobevent-requisite-subtype-variant-selection"
                ></v-select>
            </v-col>
            <v-col v-if="showFertilizer && $vuetify.breakpoint.smAndDown" class="col-12 pr-0">
                <v-row no-gutters class="justify-center">
                    <v-radio-group v-model="subtype" row :error-messages="subTypeError">
                        <v-radio label="Tavaline väetis" :value="FERTILIZER_TYPE.FERTILIZER"></v-radio>
                        <v-radio label="Lubiväetis" :value="FERTILIZER_TYPE.FERTILIZER_LIMESTONE"></v-radio>
                    </v-radio-group>
                </v-row>
            </v-col>

            <v-col v-if="showChemical&& isChemicalFromRegistry" class="col-12 pr-0">
                <v-select
                    :prepend-icon="mdiFormatListBulletedType()"
                    :items="chemicalSubtypes"
                    v-model="subtype"
                    :item-value="v => v.value"
                    label="Taimekaitsevahendi tüüp"
                    :error-messages="subTypeError"
                    data-cy="jobevent-requisite-chemical-type-selection"
                ></v-select>
            </v-col>
            <v-col v-if="showFertilizer" class="col-12 pr-0">
                <v-select
                    :items="fertilizerSubtypeVariants"
                    item-text="label"
                    item-value="value"
                    v-model="subtypeVariant"
                    :error-messages="subtypeVariantError"
                    :prepend-icon="mdiSelectGroup()"
                    label="Väetise tüüp"
                ></v-select>
            </v-col>

            <v-col class="col-6 col-md-4">
                <v-text-field
                    prepend-icon="opacity"
                    v-model="amountModel"
                    ref="amountModel"
                    :rules="[
                        v => !!this.$refs.amountModel && !this.$refs.amountModel.badInput || this.$refs.amountModel===undefined || ('Number ei ole korrektne'),
                        v => v > 0 || !!this.$refs.amountModel && !this.$refs.amountModel.badInput || 'Number peab olema positiivne'
                     ]"
                    type="number"
                    :error-messages="requisiteAmountError"
                    min="0"
                    data-cy="jobevent-requisite-amount-input"
                    label="Kasutusnorm"></v-text-field>
            </v-col>
            <v-col class="col-6 col-md-4 pl-4">
                <v-select
                    :items="units"
                    v-model="unitsModel"
                    :error-messages="requisiteUnitError"
                    data-cy="jobevent-requisite-units-input"
                    label="Ühik"
                ></v-select>
            </v-col>
            <v-col v-if="isAreaBasedUnits" class="col-12 col-md-4 pl-0 pl-md-4">
                <v-text-field
                    prepend-icon="view_stream"
                    v-model="totalAmountModel"
                    type="number"
                    :suffix="areaBasedUnits"
                    min="0"
                    ref="totalAmountModel"
                    :rules="[
                        v => !!this.$refs.totalAmountModel && !this.$refs.totalAmountModel.badInput || this.$refs.totalAmountModel===undefined || ('Number ei ole korrektne'),
                        v => v > 0 || !!this.$refs.totalAmountModel && !this.$refs.totalAmountModel.badInput || 'Number peab olema positiivne'
                     ]"
                    data-cy="jobevent-requisite-total-amount-input"
                    label="Kogus"></v-text-field>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col>
                <v-row no-gutters v-if="noteModel" :style="{ marginTop: '-6px' }"
                       class="primary--text text--darken-1 align-left ml-0 mb-2">
                    <v-icon color="primary darken-1">{{ mdiTextBoxEditOutline() }}</v-icon>
                    <v-col class="ml-4">Märkmed: {{ noteModel }}</v-col>
                </v-row>
                <v-row no-gutters v-if="safetyRecordModel" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">error_outline</v-icon>
                    <v-col class="ml-4">Ohutuskirje: {{ safetyRecordModel }}</v-col>
                </v-row>
                <v-row no-gutters v-if="workDelayDays" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">{{ mdiProgressClock() }}</v-icon>
                    <v-col class="ml-4">Tööoode: {{ daySuffix(workDelayDays) }}</v-col>
                </v-row>
                <v-row no-gutters v-if="harvestDelayDays" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">update</v-icon>
                    <v-col class="ml-4">Ooteaeg: {{ daySuffix(harvestDelayDays) }}</v-col>
                </v-row>
                <v-row no-gutters v-if="usageTimes" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">rotate_right</v-icon>
                    <v-col class="ml-4">Kasutuskordi: {{ usageTimes }}</v-col>
                </v-row>
                <v-row no-gutters v-if="safetyArea" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">remove_circle_outline</v-icon>
                    <v-col class="ml-4">Ohutusala: {{ safetyArea }}m</v-col>
                </v-row>
                <v-row no-gutters v-if="safetyWaterArea" :style="{ marginTop: '-6px' }"
                       class="orange--text text--darken-3 align-left ml-0 mb-2">
                    <v-icon color="orange darken-2">waves</v-icon>
                    <v-col class="ml-4">Ohutusala veepiirist (pervest): {{ safetyWaterArea }}m</v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="showChemical && isChemicalFromRegistry">
            <v-col>
                <v-autocomplete
                    v-model="ingredients"
                    :prepend-icon="mdiFlaskRoundBottom()"
                    :items="chemicalIngredients"
                    ref="ingredients"
                    label="Toimeained"
                    multiple
                    chips
                    deletable-chips
                    item-text="name"
                    item-value="code"
                    return-object
                    :error-messages="ingredientsError"
                    data-cy="requisite-chemical-ingredients-select"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col :class="$vuetify.breakpoint.mdAndUp && showChemical ? 'col-12': 'col-12'" v-if="showTypeSelection">
                <v-select
                    prepend-icon="category"
                    ref="requisiteTypes"
                    :items="requisiteTypes"
                    v-model="type"
                    :item-text="requisiteTypeName"
                    :class="$vuetify.breakpoint.mdAndUp && showChemical ? 'pr-0 pr-md-4' : ''"
                    :item-value="requisiteTypeObject"
                    label="Tarviku tüüp"
                    :error-messages="requisiteTypeError"
                    no-data-text="Töö tüübile ei ole seadistatud tarvikuid"
                    data-cy="jobevent-requisite-type-select"
                ></v-select>
            </v-col>

        </v-row>
        <v-row no-gutters v-if="showSeed">
            <v-col class="col-12 col-md-7">
                <v-combobox
                    :prepend-icon="mdiDotsTriangle()"
                    :items="seedTypes"
                    v-model="subtype"
                    label="Taime liik"
                    auto-select-first
                    class="pr-0 pr-md-4"
                    :error-messages="subTypeError"
                    :menu-props="{auto: $vuetify.breakpoint.mdAndUp}"
                    data-cy="requisite-seed-type-select"
                ></v-combobox>
            </v-col>
            <v-col class="col-12  px-0 pl-md-2" :class="showBatchNo ? 'col-md-2' : 'col-md-5'">
                <v-select
                    ref="seedCertificateModel"
                    :prepend-icon="mdiCertificateOutline()"
                    v-model="seedCertificate"
                    label="Sertifikaat"
                    :items="seedCertificates"
                    item-text="label"
                    item-value="value"
                    :rules="[v => !!v || 'Kohustuslik väli']"
                    required
                    :error-messages="seedCertificate ? null : ['Kohustuslik väli']"
                    data-cy="requisite-seed-certificate-select"
                ></v-select>
            </v-col>
            <v-col v-if="showBatchNo" class="col-12 col-md-3 px-0 pl-md-2">
                <v-text-field
                    ref="seedBatchModel"
                    :prepend-icon="mdiNumeric()"
                    v-model="batchNo" label="Partii number"
                    data-cy="requisite-seed-batchno-input"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="showCoating">
            <v-col class="col-12 col-sm-7 pr-2">
                <v-autocomplete
                    :prepend-icon="mdiFlaskOutline()"
                    item-text="name"
                    :items="coatingChemicals"
                    return-object
                    v-model="coatingRequisiteModel"
                    ref="coatingRequisiteModel"
                    :attach="$vuetify.breakpoint.smAndDown"
                    :label="isMissingCoatingRequisite ? 'Puhtimisvahendit \'' + this.coating.name + '\' ei leitud' : 'Puhtimisvahendi nimetus'"
                    auto-select-first
                    :rules="[v => !!v || 'Kohustuslik väli']"
                    :error-messages="coatingError"
                    @update:search-input=customSortCoatingChemicals
                    v-on:click:append="() => provideCloseDropdown(this.$refs.coatingRequisiteModel)"
                    data-cy="jobevent-requisite-coating-name"
                >
                    <template v-slot:no-data>
                        <v-col class="mx-4">
                            Sellise nimega taimekaitsevahendit ei leitud
                        </v-col>
                    </template>
                    <template #item="{item}">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }} <span
                                style="color: #575757; font-weight: 300">{{
                                    requisiteAmountAndUnitValue(item)
                                }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                        <v-row no-gutters class="pr-5 pr-sm-auto my-2 px-sm-4">
                            <v-btn
                                color="primary"
                                class="mb-2"
                                block
                                data-cy="jobevent-requisite-add-new"
                                @click.stop="() => {forcedChemicalType = true; addNewRequisite()}">
                                <v-icon>add</v-icon>
                                Lisa taimekaitsevahend
                            </v-btn>
                        </v-row>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col class="col-6 col-sm-3">
                <v-text-field
                    ref="maxCoatingAmount"
                    prepend-icon="opacity"
                    type="number"
                    min="0"
                    :error-messages="coatingAmountError"
                    v-model="coating.amount" label="Puhtimisvahendi norm"
                    :rules="[v => !!this.$refs.maxCoatingAmount && !this.$refs.maxCoatingAmount.badInput || this.$refs.maxCoatingAmount===undefined || 'Number ei ole korrektne']"
                ></v-text-field>
            </v-col>
            <v-col class="col-6 col-sm-2">
                <v-autocomplete
                    :items="coatingUnits"
                    v-model="coating.unit"
                    class="pl-4"
                    :error-messages="coatingUnitError"
                    label="Ühik"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="showFertilizer">
            <v-col class="col-4 col-md-4 pl-1 pl-md-2">
                <v-text-field
                    type="number"
                    min="0"
                    v-model="nutrient.N"
                    ref="elementNModel"
                    :suffix="nutrientUnitSuffix"
                    :rules="[v => !!this.$refs.elementNModel && !this.$refs.elementNModel.badInput || this.$refs.elementNModel===undefined || 'Number ei ole korrektne']"
                    class="pr-2 pr-md-0"
                    data-cy="jobevent-requisite-fertilizer-n-input"
                >
                    <template v-slot:prepend><strong style="color:rgba(0,0,0,0.6)">N:</strong></template>
                </v-text-field>
            </v-col>
            <v-col class="col-4 col-md-4 pl-0 pl-md-4">
                <v-text-field
                    type="number"
                    min="0"
                    :suffix="nutrientUnitSuffix"
                    v-model="nutrient.P"
                    ref="elementPModel"
                    :rules="[v => !!this.$refs.elementPModel && !this.$refs.elementPModel.badInput || this.$refs.elementPModel===undefined || 'Number ei ole korrektne']"
                    :style="$vuetify.breakpoint.mdAndUp && 'margin-right: 10px'"
                    class="px-2 px-md-0"
                    data-cy="jobevent-requisite-fertilizer-p-input"
                >
                    <template v-slot:prepend><strong style="color:rgba(0,0,0,0.6)">P:</strong></template>
                </v-text-field>
            </v-col>
            <v-col class="col-4 col-md-4 pl-0 pl-md-4">
                <v-text-field
                    type="number"
                    min="0"
                    v-model="nutrient.K"
                    ref="elementKModel"
                    :suffix="nutrientUnitSuffix"
                    :rules="[v => !!this.$refs.elementKModel && !this.$refs.elementKModel.badInput || this.$refs.elementKModel===undefined || 'Number ei ole korrektne']"
                    class="pl-2 pl-md-0"
                    data-cy="jobevent-requisite-fertilizer-k-input"
                >
                    <template v-slot:prepend><strong style="color:rgba(0,0,0,0.6)">K:</strong></template>
                </v-text-field>
            </v-col>
            <v-col class="col-6 col-md-4 pl-0 pl-md-2 pr-2 pr-md-0">
                <v-text-field
                    type="number"
                    min="0"
                    ref="elementCaOModel"
                    :suffix="nutrientUnitSuffix"
                    :rules="[v => !!this.$refs.elementCaOModel && !this.$refs.elementCaOModel.badInput || this.$refs.elementCaOModel===undefined || 'Number ei ole korrektne']"
                    v-model="nutrient.CaO"
                >
                    <template v-slot:prepend><strong style="color:rgba(0,0,0,0.6)">CaO :</strong></template>
                </v-text-field>
            </v-col>
            <v-col class="col-6 col-md-4 pl-2 pl-md-4 pr-0 pr-md-2">
                <v-text-field
                    type="number"
                    min="0"
                    ref="elementMgOModel"
                    :suffix="nutrientUnitSuffix"
                    :rules="[v => !!this.$refs.elementMgOModel && !this.$refs.elementMgOModel.badInput || this.$refs.elementMgOModel===undefined || 'Number ei ole korrektne']"
                    v-model="nutrient.MgO"
                >
                    <template v-slot:prepend><strong style="color:rgba(0,0,0,0.6)">MgO :</strong></template>
                </v-text-field>
            </v-col>
        </v-row>

        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="showRequisiteManagementModal" absolute
                  style="z-index: 300"
                  max-width="800px">
            <requisiteManagementModal @close-modal="requisiteModalClosed" :initialRequisite="blankRequisite">
            </requisiteManagementModal>
        </v-dialog>
    </v-col>
</template>
<script>
import {capitalizeFirstLetter} from "../utils/stringUtils";
import {ALL_REQUISITES, REQUISITE_MATERIAL, REQUISITE_CHEMICAL, REQUISITE_FERTILIZER, REQUISITE_SEED} from "../constant/requisiteType";
import {ANIMATION} from "../../../dist/common/constants.js"
import requisiteManagementModal from "../management/requisiteManagementModal"

import _ from "lodash";
import {
    chemicalTypeDisplay,
    fertilizerDropdown,
    provideCloseDropdown,
    requisiteSelectionMethods
} from "../mixins/mixins";
import {
    mdiCertificateOutline,
    mdiDotsTriangle,
    mdiFlaskOutline,
    mdiFlaskRoundBottom,
    mdiFormatListBulletedType,
    mdiNumeric,
    mdiPackageVariantClosed, mdiProgressClock,
    mdiSeedOutline, mdiSelectGroup, mdiTextBoxEditOutline
} from "@mdi/js";
import {mapGetters} from "vuex";
import DeleteButton from "@/components/deleteButton.vue";
import {
    chemicalSubtypeVariants,
    FERTILIZER_TYPE,
    FERTILIZER_TYPE_OLD,
    regularFertilizerSubtypeVariants, limestoneFertilizerSubtypeVariants
} from "../../../dist/common/constants";
import {isMineralFertilizer} from "../../../backend/common/fertilizationHelper";

export default {
    props: {
        requisiteBlock: {
            type: Object
        },
        jobRequirements: {
            type: Array
        },
        removeRequisite: {
            type: Function
        },
        addRequisite: {
            type: Function
        },
        isCreatingNewProperty: {
            type: Boolean
        },
        showRemoveButton: {
            type: Boolean,
            default: true
        },
        insideModal: {
            type: Boolean
        },
        showRequisiteTypeSelection: {
            type: Boolean,
            default: true
        },
        area: {}
    },
    data: function () {
        return {
            showRequisiteManagementModal: false,
            requisiteError: null,
            requisiteModel: null,
            requisites: [],
            nutrient: {},
            coating: {},
            amountModel: null,
            noteModel: null,
            ingredients: [],
            safetyRecordModel: null,
            workDelayDays: null,
            harvestDelayDays: null,
            usageTimes: null,
            safetyArea: null,
            safetyWaterArea: null,
            unitsModel: null,
            batchNo: null,
            seedCertificate: null,
            requisiteUnitError: null,
            requisiteAmountError: null,
            coatingUnitError: null,
            coatingAmountError: null,
            requisiteTypeError: null,
            ingredientsError: null,
            subTypeError: null,
            subtypeVariantError: null,
            type: null,
            subtype: null,
            subtypeVariant: null,
            requirements: this.jobRequirements,
            dynamicallyCreatedRequisite: false,
            showAddRequisiteReminder: true,
            totalAmountModel: null,
            updatingRequisiteAmountField: false,
            coatingChemicals: [],
            coatingRequisiteModel: null,
            coatingError: null,
            forcedChemicalType: false // Used to force chemical type when adding new coating requisite for seed
        }
    },
    components: {
        DeleteButton,
        requisiteManagementModal
    },
    mixins: [provideCloseDropdown, fertilizerDropdown, requisiteSelectionMethods, chemicalTypeDisplay],
    computed: {
        stateRequisites() {
            let missingRequisites = [];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dynamicallyCreatedRequisite = false;
            if (this.isModifingExisting()) {
                let existingRequisite = this.findByName(this.$store.getters.getAllRequisites, this.requisiteBlock.name, this.requisiteBlock.amount, this.requisiteBlock.note);
                if (existingRequisite === undefined) {
                    missingRequisites.push({
                        name: this.requisiteBlock.name,
                        amount: this.requisiteBlock.amount,
                        note: this.requisiteBlock.note
                    });
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.dynamicallyCreatedRequisite = true;
                }
            }
            let existingRequisites = this.$store.getters.getRequisitesByType(this.requirements);
            if (this.type === REQUISITE_FERTILIZER.typeName && this.subtype) {
                if (this.subtype === FERTILIZER_TYPE.FERTILIZER_LIMESTONE) {
                    const limestoneRequisites = existingRequisites.filter(requisite => {
                        return requisite.subtype === this.subtype
                    });
                    if (limestoneRequisites.length > 0) {
                        existingRequisites = limestoneRequisites
                    }
                }
            }
            return _.concat(existingRequisites, missingRequisites);
        },
        requisiteTypes() {
            return _.filter(ALL_REQUISITES, requisite => {
                return _.includes(this.requirements, requisite.typeName)
            });
        },
        blankRequisite() {
            let typeOfRequisite = this.type;
            if ((typeOfRequisite === undefined || typeOfRequisite === null) && this.requisiteTypes && this.requisiteTypes.length > 0) {
                typeOfRequisite = this.requisiteTypes[0].typeName
            }
            if (this.forcedChemicalType) {
                typeOfRequisite = REQUISITE_CHEMICAL.typeName
            }
            return {type: typeOfRequisite, subtype: undefined, nutrient: {}, coating: {}, customFieldArea: {}};
        },
        requisitesTypeNames() {
            if (this.type === REQUISITE_SEED.typeName) {
                return [REQUISITE_SEED.displayName, REQUISITE_SEED.displayNameWhos];
            }
            if (this.type === REQUISITE_FERTILIZER.typeName) {
                return [REQUISITE_FERTILIZER.displayName, REQUISITE_FERTILIZER.displayNameWhos];
            }
            if (this.type === REQUISITE_CHEMICAL.typeName) {
                if (this.subtypeVariant === "MARGAJA") {
                    return ["märgaja/kleepaine", "märgaja/kleepaine"];
                }
                if (this.subtypeVariant === "BIOSTIM") {
                    return ["biostimulaator", "biostimulaatori"];
                }
                if (this.subtypeVariant === "KASVUINH") {
                    return ["kasvuinhibiitor", "kasvuinhibiitori"];
                }
                return [REQUISITE_CHEMICAL.displayName, REQUISITE_CHEMICAL.displayNameWhos];
            }
            return ["tarvik", "tarviku"];
        },
        requisitesTypeNameLabel() {
            let names = this.requisitesTypeNames;
            return capitalizeFirstLetter(names[1]) + " nimi";
        },
        units() {
            return [...this.$store.getters.units, this.unitsModel]
        },
        seedTypes() {
            return this.$store.getters.getSeedTypes
        },
        chemicalSubtypes() {
            return this.$store.getters.getChemicalSubtypes.map(subtype => {
                return {
                    value: subtype,
                    text: capitalizeFirstLetter(this.chemicalTypeText(subtype))
                }
            })
        },
        showFertilizer() {
            return this.type === 'fertilizer' || (this.isDefined(this.nutrient && (this.nutrient.N || this.nutrient.K || this.nutrient.P || this.nutrient.CaO || this.nutrient.MgO)));
        },
        showChemical() {
            return this.type === 'chemical';
        },
        showSeed() {
            return this.type === 'seed' || (this.isDefined(this.subtype) && this.type !== 'fertilizer' && this.type !== 'chemical');
        },
        showCoating() {
            return (this.type === 'seed' && this.isDefined(this.coating) && this.isDefined(this.coating.name));
        },
        showBatchNo() {
            return (this.seedCertificate !== undefined && this.seedCertificate !== 'OTS') || (this.requisiteModelIsDefined && (this.isDefined(this.requisiteModel.batchNo))) || this.isDefined(this.batchNo);
        },
        requisiteModelIsDefined() {
            return this.isDefined(this.requisiteModel);
        },
        showTypeSelection() {
            return this.showRequisiteTypeSelection && this.requisiteTypes.length > 1;
        },
        requisiteIcon: function () {
            if (this.type === 'fertilizer') return mdiDotsTriangle;
            if (this.type === 'seed') return mdiSeedOutline;
            if (this.type === 'chemical') return mdiFlaskOutline;
            return mdiPackageVariantClosed
        },
        coatingUnits() {
            return this.$store.getters.coatingUnits;
        },
        nutrientUnitSuffix: function () {
            if (this.isMineralFertilizer) {
                return "%"
            } else {
                return "kg/t"
            }
        },
        isMineralFertilizer() {
            return isMineralFertilizer(this.subtype, this.subtypeVariant)
        },
        isAreaBasedUnits() {
            return this.unitsModel && this.unitsModel.endsWith("/ha");
        },
        areaBasedUnits() {
            if (this.isAreaBasedUnits) {
                return this.unitsModel.split("/")[0]
            }
            return undefined
        },
        chemicalIngredients() {
            return this.$store.getters.getChemicalIngredients
        },
        stateCoatingChemicals() {
            return _.cloneDeep(this.getRequisitesByType()(REQUISITE_CHEMICAL)
                .filter(requisite =>
                    requisite.subtypeVariant === 'TKV_REGISTRIST' ||
                    requisite.subtypeVariant === 'BIOSTIM' ||
                    requisite.subtypeVariant === null ||
                    requisite.subtypeVariant === undefined
                ))
        },
        isMissingCoatingRequisite() {
            return (this.coating && this.coating.name && this.coating.requisiteId === undefined && this.coatingRequisiteModel === null) ||
                (this.coating && this.coating.name && this.coatingRequisiteModel === null)
        },
        seedCertificates() {
            return this.$store.getters.getSeedCertificates
        },
        chemicalSubtypeVariants() {
            return chemicalSubtypeVariants
        },
        isChemicalFromRegistry() {
            return this.type === REQUISITE_CHEMICAL.typeName && this.subtypeVariant === "TKV_REGISTRIST"
        },
        fertilizerSubtypeVariants() {
            if (this.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE) {
                return regularFertilizerSubtypeVariants
            } else {
                return limestoneFertilizerSubtypeVariants
            }
        },
        FERTILIZER_TYPE() {
            return FERTILIZER_TYPE
        },
    },

    watch: {
        requisiteTypes: {
            handler: function () {
                if (this.requisiteTypes.length === 1) {
                    this.type = this.requisiteTypes[0].typeName;
                }
            },
            deep: true
        },
        stateRequisites: {
            handler: function (requisites) {
                this.requisites = _.clone(requisites);
            },
            immediate: true
        },
        type: function () {
            this.$emit('type', this.type);
            if (this.type !== REQUISITE_SEED.typeName) {
                this.batchNo = null
                this.seedCertificate = null
                this.coating = {}
            }
            if (this.type !== REQUISITE_FERTILIZER.typeName) {
                this.nutrient = {}
            }
            if (this.type !== REQUISITE_SEED.typeName && this.type !== REQUISITE_FERTILIZER.typeName && this.type !== REQUISITE_CHEMICAL.typeName) {
                this.subtype = null
                this.subtypeVariant = null
            }
            if (this.type !== REQUISITE_CHEMICAL.typeName) {
                this.subtypeVariant = this.seedCertificates[0].value
                this.workDelayDays = null;
                this.safetyRecordModel = null;
                this.noteModel = null;
                this.ingredients = [];
                this.harvestDelayDays = null;
                this.usageTimes = null;
                this.safetyArea = null;
                this.safetyWaterArea = null;
            }
            if (this.type === REQUISITE_FERTILIZER.typeName) {
                if (!this.subtype) {
                    this.subtype = FERTILIZER_TYPE.FERTILIZER
                }
                if (this.subtype !== FERTILIZER_TYPE.FERTILIZER &&
                    this.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE) { // OLD TYPE SUPPORT
                    this.subtype = null;
                }
                if (!_.some(this.fertilizerSubtypeVariants, (variant) => variant.value === this.subtypeVariant)) {
                    this.subtypeVariant = null;
                }
                if (!this.subtypeVariant) {
                    this.subtypeVariant = this.fertilizerSubtypeVariants[0].value;
                }
            }

            if (this.type === REQUISITE_CHEMICAL.typeName) {
                if (!_.some(this.chemicalSubtypes, (variant) => variant.value === this.subtype)) {
                    this.subtype = null
                }
                if (!_.some(this.chemicalSubtypeVariants, (variant) => variant.value === this.subtypeVariant)) {
                    this.subtypeVariant = this.chemicalSubtypeVariants[0].value;
                }
            }

            if (this.type === REQUISITE_MATERIAL.typeName ||
                (this.type === REQUISITE_SEED.typeName && (
                    this.subtype === FERTILIZER_TYPE_OLD.MINERAL_FERTILIZER || // OLD TYPE
                    this.subtype === FERTILIZER_TYPE_OLD.ORGANIC_FERTILIZER || // OLD TYPE
                    this.subtype === FERTILIZER_TYPE.FERTILIZER ||
                    this.subtype === FERTILIZER_TYPE.FERTILIZER_LIMESTONE ||
                    _.some(this.chemicalSubtypes, (variant) => variant.value === this.subtype)
                ))) {
                this.subtype = null;
                this.subtypeVariant = null;
            }
        },
        requisiteModel: function () {
            if (this.requisiteModel) {
                let predefinedRequisite;
                if (this.requisiteModel._id) {
                    predefinedRequisite = this.findById(this.$store.getters.getAllRequisites, this.requisiteModel._id);
                } else {
                    predefinedRequisite = this.findByName(this.$store.getters.getAllRequisites, this.requisiteModel.name, this.requisiteModel.amount, this.requisiteModel.note);
                }
                if (predefinedRequisite !== null && predefinedRequisite !== undefined &&
                    (this.isCreatingNew() || this.requisiteBlock.name !== this.requisiteModel.name)) {
                    this.unitsModel = predefinedRequisite.unit;
                    this.amountModel = predefinedRequisite.amount;
                    this.noteModel = predefinedRequisite.note;
                    this.ingredients = predefinedRequisite.ingredients;
                    this.safetyRecordModel = predefinedRequisite.safetyRecord;
                    this.workDelayDays = predefinedRequisite.workDelayDays;
                    this.harvestDelayDays = predefinedRequisite.harvestDelayDays;
                    this.usageTimes = predefinedRequisite.usageTimes;
                    this.safetyArea = predefinedRequisite.safetyArea;
                    this.safetyWaterArea = predefinedRequisite.safetyWaterArea;
                    this.nutrient = Object.assign({}, predefinedRequisite.nutrient);
                    this.coating = Object.assign({}, predefinedRequisite.coating);
                    this.coatingRequisiteModel = _.find(this.stateCoatingChemicals, (e) => {
                        return e._id === this.coating.requisiteId
                    }) ?? null;
                    this.type = predefinedRequisite.type;
                    this.subtype = predefinedRequisite.subtype;
                    if (!this.subtype && this.type === REQUISITE_FERTILIZER.typeName) {
                        this.subtype = FERTILIZER_TYPE.FERTILIZER;
                    }
                    this.subtypeVariant = predefinedRequisite.subtypeVariant;
                    if (!this.subtypeVariant) {
                        if (this.type === REQUISITE_CHEMICAL.typeName) {
                            this.subtypeVariant = this.chemicalSubtypeVariants[0].value;
                        }
                    }
                    this.batchNo = predefinedRequisite.batchNo;
                    this.seedCertificate = predefinedRequisite.seedCertificate;
                    this.$emit('inspections', predefinedRequisite.inspections)
                    this.$emit('customFieldArea', predefinedRequisite.customFieldArea)
                    this.$emit('requisiteForWarningCheck', _.cloneDeep(predefinedRequisite))
                } else if (this.isDefined(this.requisiteBlock.name)) {
                    this.unitsModel = this.requisiteBlock.unit;
                    this.amountModel = this.requisiteBlock.amount;
                    this.noteModel = this.requisiteBlock.note;
                    this.ingredients = this.requisiteBlock.ingredients;
                    this.safetyRecordModel = this.requisiteBlock.safetyRecord;
                    this.workDelayDays = this.requisiteBlock.workDelayDays;
                    this.harvestDelayDays = this.requisiteBlock.harvestDelayDays;
                    this.usageTimes = this.requisiteBlock.usageTimes;
                    this.safetyArea = this.requisiteBlock.safetyArea;
                    this.safetyWaterArea = this.requisiteBlock.safetyWaterArea;
                    this.type = this.requisiteBlock.type;
                    this.subtype = this.requisiteBlock.subtype;
                    this.subtypeVariant = this.requisiteBlock.subtypeVariant;
                    if (this.subtypeVariant === undefined) { // If the requisite is old and without subtype variant, then initialize and make it reactive
                        this.$set(this.requisiteBlock, 'subtypeVariant', null);
                    }
                    if ((this.subtypeVariant === undefined || this.subtypeVariant === null) && this.type === REQUISITE_CHEMICAL.typeName) {
                        this.subtypeVariant = this.chemicalSubtypeVariants[0].value;
                    }
                    this.batchNo = this.requisiteBlock.batchNo;
                    this.seedCertificate = this.requisiteBlock.seedCertificate;
                    this.nutrient = Object.assign({}, this.requisiteBlock.nutrient);
                    this.coating = Object.assign({}, this.requisiteBlock.coating);
                    if (predefinedRequisite !== null && predefinedRequisite !== undefined &&
                        predefinedRequisite.coating !== null && predefinedRequisite.coating !== undefined &&
                        (this.requisiteBlock.coating === undefined || this.requisiteBlock.coating === null || Object.keys(this.requisiteBlock.coating).length === 0)) {
                        //this.requisiteBlock.coating === undefined come from fertilization plan dynamic requisites
                        this.coating = Object.assign({}, predefinedRequisite.coating);
                    }
                    this.coatingRequisiteModel = _.find(this.stateCoatingChemicals, (e) => {
                        return e._id === this.coating.requisiteId
                    }) ?? null;
                    this.$emit('requisiteForWarningCheck', _.cloneDeep(this.requisiteBlock))
                }

                this.$emit('type', this.type);
                this.$emit('subtype', this.subtype);

                this.$nextTick(() => {
                    if (this.$refs.requisiteModel) {
                        this.$refs.requisiteModel.blur();
                    }
                });
                // this.requisiteUnitError= null; //TODO: try to clear errors after requisite is changed ( selected from dropdown a new one)
                // this.coatingUnitError= null;
                // this.seedTypeError= null;
            } else {
                this.unitsModel = null;
                this.amountModel = null;
                this.noteModel = null;
                this.ingredients = [];
                this.safetyRecordModel = null;
                this.workDelayDays = null;
                this.harvestDelayDays = null;
                this.usageTimes = null;
                this.safetyArea = null;
                this.safetyWaterArea = null;
                if (this.requisiteTypes.length === 1) {
                    this.type = this.requisiteTypes[0].typeName;
                } else {
                    this.type = null;
                }
                this.subtype = null;
                this.subtypeVariant = null;
                if (this.type === REQUISITE_CHEMICAL.typeName) {
                    this.subtypeVariant = this.chemicalSubtypeVariants[0].value;
                }
                this.batchNo = null;
                this.seedCertificate = null;
                this.nutrient = Object.assign({}, null);
                this.coating = Object.assign({}, null);
                this.updateRequsite()
            }
            // this.requisiteError = null;
            // this.requisiteTypeError = null
            // this.subTypeError = null;
        },
        requisiteBlock: {
            handler: function (givenRequisite) {
                this.requisiteModel = givenRequisite.name ? {
                    name: givenRequisite.name,
                    amount: givenRequisite.amount,
                    note: givenRequisite.note
                } : null;
                this.unitsModel = givenRequisite.unit;
                this.amountModel = givenRequisite.amount;
                this.nutrient = Object.assign({}, givenRequisite.nutrient);
                this.coating = Object.assign({}, givenRequisite.coating);
                this.noteModel = givenRequisite.note;
                this.ingredients = givenRequisite.ingredients;
                this.safetyRecordModel = givenRequisite.safetyRecord;
                this.workDelayDays = givenRequisite.workDelayDays;
                this.harvestDelayDays = givenRequisite.harvestDelayDays;
                this.usageTimes = givenRequisite.usageTimes;
                this.safetyArea = givenRequisite.safetyArea;
                this.safetyWaterArea = givenRequisite.safetyWaterArea;
                this.type = givenRequisite.type;
                this.subtype = givenRequisite.subtype;
                this.subtypeVariant = givenRequisite.subtypeVariant;
                this.batchNo = givenRequisite.batchNo;
                this.seedCertificate = givenRequisite.seedCertificate;
                this.requisiteError = givenRequisite.requisiteError;
                this.requisiteUnitError = givenRequisite.requisiteUnitError;
                this.requisiteAmountError = givenRequisite.requisiteAmountError;
                this.coatingAmountError = givenRequisite.coatingAmountError;
                this.coatingUnitError = givenRequisite.coatingUnitError;
                this.requisiteTypeError = givenRequisite.requisiteTypeError;
                this.ingredientsError = givenRequisite.ingredientsError;
                this.coatingError = givenRequisite.coatingError;
                this.subTypeError = givenRequisite.subTypeError;
                this.subtypeVariantError = givenRequisite.subtypeVariantError;
            },
            deep: true
        },
        unitsModel: function () {
            this.requisiteUnitError = null;
        },
        amountModel: function () {
            this.requisiteAmountError = null;
            if (!this.updatingRequisiteAmountField) {
                this.updatingRequisiteAmountField = true;
                if (this.amountModel && this.area) {
                    this.totalAmountModel = _.round(this.amountModel * this.area, 4);
                }
                this.$nextTick(() => {
                    this.updatingRequisiteAmountField = false;
                })
            }
        },
        area: function () {
            if (!this.updatingRequisiteAmountField) {
                this.updatingRequisiteAmountField = true;
                if (this.amountModel && this.area) {
                    this.totalAmountModel = this.amountModel * this.area;
                }
                this.$nextTick(() => {
                    this.updatingRequisiteAmountField = false;
                })
            }
        },
        totalAmountModel: function () {
            if (!this.updatingRequisiteAmountField) {
                this.updatingRequisiteAmountField = true;
                if (this.totalAmountModel === "") {
                    this.amountModel = null;
                }
                if (this.totalAmountModel && this.area) {
                    this.amountModel = _.round(this.totalAmountModel / this.area, 3);
                }
                this.$nextTick(() => {
                    this.updatingRequisiteAmountField = false;
                })
            }
        },
        coating: {
            handler: function (newCoating) {
                if (newCoating.unit !== undefined && newCoating.unit !== null) {
                    this.coatingUnitError = null;
                }
                if (newCoating.amount !== undefined && newCoating.amount !== null && newCoating.amount !== '') {
                    this.coatingAmountError = null;
                }
            },
            deep: true
        },
        coatingRequisiteModel: {
            handler: function (newCoating, oldCoating) {
                if (oldCoating === null || (newCoating && newCoating.requisiteId !== oldCoating.requisiteId)) {
                    this.coatingError = null;
                }
            },
            deep: true
        },
        subtype: {
            handler: function () {
                if (this.type === REQUISITE_FERTILIZER.typeName && (
                    this.subtype !== FERTILIZER_TYPE.FERTILIZER &&
                    this.subtype !== FERTILIZER_TYPE.FERTILIZER_LIMESTONE
                )) {
                    this.subTypeError = ["Kohustuslik väli"];
                } else {
                    this.subTypeError = null;
                }
                if (this.type === REQUISITE_FERTILIZER.typeName &&
                    !_.some(this.fertilizerSubtypeVariants, (variant) => variant.value === this.subtypeVariant)) {
                    this.subtypeVariant = null;
                    this.subtypeVariantError = ["Kohustuslik väli"];
                }
                if (this.type === REQUISITE_FERTILIZER.typeName && !this.unit) {
                    this.unit = this.units[0];
                }
                if (this.type === REQUISITE_CHEMICAL.typeName && !_.some(this.chemicalSubtypeVariants, (variant) => variant.value === this.subtypeVariant)) {
                    this.subtypeVariant = this.chemicalSubtypeVariants[0].value
                }
                this.$emit('subtype', this.subtype);
            },
            deep: true
        },
        subtypeVariant: {
            handler: function () {
                if (this.subtypeVariant && this.subtypeVariant.length > 0) {
                    this.subtypeVariantError = null;
                }
                if (this.type === REQUISITE_FERTILIZER.typeName &&
                    !_.some(this.fertilizerSubtypeVariants, (variant) => variant.value === this.subtypeVariant)) {
                    this.subtypeVariant = null;
                    this.subtypeVariantError = ["Kohustuslik väli"];
                }
            },
            deep: true
        },
        jobRequirements: function () {
            this.requirements = this.jobRequirements
        },
        stateCoatingChemicals() {
            this.coatingChemicals = this.stateCoatingChemicals;
        },
    },
    created() {
        this.requisiteModel = this.requisiteBlock.name ? {
            name: this.requisiteBlock.name,
            amount: this.requisiteBlock.amount,
            note: this.requisiteBlock.note
        } : null;
        this.unitsModel = this.requisiteBlock.unit;
        this.amountModel = this.requisiteBlock.amount;
        this.safetyRecordModel = this.requisiteBlock.safetyRecord;
        this.noteModel = this.requisiteBlock.note;
        this.ingredients = this.requisiteBlock.ingredients;
        this.workDelayDays = this.requisiteBlock.workDelayDays,
        this.harvestDelayDays = this.requisiteBlock.harvestDelayDays,
        this.usageTimes = this.requisiteBlock.usageTimes,
        this.safetyArea = this.requisiteBlock.safetyArea,
        this.safetyWaterArea = this.requisiteBlock.safetyWaterArea;
        if (this.requisiteModel === null && this.requisiteTypes.length === 1) {
            this.type = this.requisiteTypes[0].typeName;
        } else {
            this.type = this.requisiteBlock.type;
        }
        this.subtype = this.requisiteBlock.subtype;
        this.subtypeVariant = this.requisiteBlock.subtypeVariant;
        this.batchNo = this.requisiteBlock.batchNo;
        this.seedCertificate = this.requisiteBlock.seedCertificate;
        this.nutrient = Object.assign({}, this.requisiteBlock.nutrient);
        this.coating = Object.assign({}, this.requisiteBlock.coating);
        if (this.requisiteBlock.coating && this.requisiteBlock.coating.requisiteId) {
            this.coatingRequisiteModel = _.find(this.stateCoatingChemicals, (e) => {
                return e._id === this.requisiteBlock.coating.requisiteId
            }) ?? null;
        }
        this.requisiteError = this.requisiteBlock.requisiteError;
        this.requisiteUnitError = this.requisiteBlock.requisiteUnitError;
        this.requisiteAmountError = this.requisiteBlock.requisiteAmountError;
        this.coatingUnitError = this.requisiteBlock.coatingUnitError;
        this.coatingAmountError = this.requisiteBlock.coatingAmountError;
        this.coatingError = this.requisiteBlock.coatingError;
        this.requisiteTypeError = this.requisiteBlock.requisiteTypeError;
        this.ingredientsError = this.requisiteBlock.ingredientsError;
        this.subTypeError = this.requisiteBlock.subTypeError;
        this.subtypeVariantError = this.requisiteBlock.subtypeVariantError;
    },

    methods: {
        mdiSelectGroup() {
            return mdiSelectGroup
        },
        mdiProgressClock() {
            return mdiProgressClock
        },
        mdiTextBoxEditOutline() {
            return mdiTextBoxEditOutline
        },
        mdiCertificateOutline() {
            return mdiCertificateOutline
        },
        mdiNumeric() {
            return mdiNumeric
        },
        ...mapGetters(["getRequisitesByType"]),
        mdiFlaskOutline() {
            return mdiFlaskOutline
        },
        mdiFormatListBulletedType() {
            return mdiFormatListBulletedType
        },
        mdiFlaskRoundBottom() {
            return mdiFlaskRoundBottom
        },
        mdiDotsTriangle() {
            return mdiDotsTriangle
        },
        requisiteModalClosed: function (newRequisiteInfo) {
            this.showRequisiteManagementModal = false;
            // When creating new then clear the field to make sure user select correct requisites after adding a new one
            // When modifying existing then do not change because the requisite could be dynamically added (missing)
            if (this.isCreatingNew() && !this.forcedChemicalType) {
                this.requisiteModel = undefined;
                if (newRequisiteInfo) {
                    this.requisiteModel = newRequisiteInfo;
                }
                if (this.requisites && this.requisites.length === 1) {
                    this.requisiteModel = this.requisites[0];
                }
            }
            this.forcedChemicalType = false;
        },
        updateRequsite: function () {
            if (this.$refs.requisiteModel) {
                // Internal api, not recommended. Put into timeout maybe?
                this.$refs.requisiteModel.updateCombobox()
            }
            let requisiteName = this.requisiteModel && this.requisiteModel.name ? this.requisiteModel.name : this.requisiteModel;
            this.$emit('update:name', requisiteName);
            this.$emit('update:unit', this.unitsModel);
            this.$emit('update:amount', this.amountModel);
            this.$emit('update:safetyRecord', this.safetyRecordModel);
            this.$emit('update:workDelayDays', this.workDelayDays);
            this.$emit('update:harvestDelayDays', this.harvestDelayDays);
            this.$emit('update:usageTimes', this.usageTimes);
            this.$emit('update:safetyArea', this.safetyArea);
            this.$emit('update:safetyWaterArea', this.safetyWaterArea);
            this.$emit('update:subtype', this.subtype);
            this.$emit('update:subtypeVariant', this.subtypeVariant);
            this.$emit('update:type', this.type);
            this.$emit('update:batchNo', this.batchNo);
            this.$emit('update:seedCertificate', this.seedCertificate);
            this.$emit('update:nutrient', this.nutrient);
            this.$emit('update:note', this.noteModel);
            this.$emit('update:ingredients', this.ingredients);
            if (!this.isDefined(this.coating.name)) {
                this.coating = {};
            }
            if (this.coatingRequisiteModel) {
                this.coating.name = this.coatingRequisiteModel.name;
                this.coating.requisiteId = this.coatingRequisiteModel._id;
            }
            this.$emit('update:coating', this.coating);
        },
        isCreatingNew: function () {
            return !this.isModifingExisting()
        },
        clickAndFocusRequisite: function () {
            this.$refs.requisiteModel.focus()
            this.$refs.requisiteModel.activateMenu();
        },
        isModifingExisting: function () {
            return this.requisiteBlock !== null &&
                this.requisiteBlock !== undefined &&
                this.requisiteBlock.name !== null &&
                this.requisiteBlock.name !== undefined
        },
        findById: function (collection, idToFind) {
            return _.find(collection, (e) => {
                return e._id === idToFind
            })
        },
        daySuffix: function (numberOfDays) {
            if (numberOfDays > 1) {
                return numberOfDays + " päeva"
            } else {
                return numberOfDays + " päev"
            }
        },
        findByName: function (collection, nameToFind, amount, note) {
            let matches = _.filter(collection, (e) => {
                return e.name === nameToFind && e.amount == amount
            });
            if (matches.length === 1) {
                return matches[0];
            }
            matches = _.filter(collection, (e) => {
                return e.name === nameToFind
            });
            if (matches.length === 1) {
                return matches[0];
            }

            if (matches.length > 1) {
                const noteMatching = _.find(matches, (e) => {
                    return e.note === note
                });
                if (noteMatching) {
                    return noteMatching;
                } else {
                    return matches[0];
                }
            }
            return undefined
        },
        requisiteTypeName: function (requisiteType) {
            return capitalizeFirstLetter(requisiteType.displayName);
        },
        requisiteTypeObject: function (requisiteType) {
            return requisiteType.typeName;
        },
        fertilizerTypeName: function (requisiteType) {
            return capitalizeFirstLetter(requisiteType.name);
        },
        fertilizerTypeObject: function (requisiteType) {
            return requisiteType.value;
        },
        isDefined: function (something) {
            return something !== null && something !== undefined && something !== ''
        },
        scroll: function (e) {
            if (this.$vuetify.breakpoint.smAndDown) {
                let options = Object.assign({}, ANIMATION.SCROLL);
                if (this.insideModal) {
                    options.container = '.jobManagementModal'
                }
                this.$vuetify.goTo(e.target, options);
            }
        },
        addNewRequisite() {
            this.showRequisiteManagementModal = true;
        },
        customSortCoatingChemicals: function (searchValue) {
            if (searchValue && searchValue.length > 0) {
                const searchValueLowerCase = searchValue.toLowerCase();
                this.coatingChemicals.sort((first, second) => {
                    const aName = first.name.toLowerCase();
                    const bName = second.name.toLowerCase();
                    const aStartsWithSearch = aName.startsWith(searchValueLowerCase) ? 1 : 0;
                    const bStartsWithSearch = bName.startsWith(searchValueLowerCase) ? 1 : 0;
                    return bStartsWithSearch - aStartsWithSearch;
                });
            } else {
                this.coatingChemicals = _.clone(this.stateCoatingChemicals);
            }
        },
        requisiteIconByType: function (element) {
            if (element === 'fertilizer') return mdiDotsTriangle;
            if (element === 'seed') return mdiSeedOutline;
            if (element === 'chemical') return mdiFlaskOutline;
            return mdiPackageVariantClosed
        },
    },
    mounted() {
        this.$on('updateRequisite', this.updateRequsite);
        this.$on('clickAndFocusRequisite', this.clickAndFocusRequisite);
        this.$store.dispatch('loadChemicalIngredientsIfNeeded')
    },
}
</script>