<template>
    <v-dialog v-model="showModal" persistent max-width="600">
        <v-card>
            <v-card-title class="grey lighten-4 text-h6">Valmis! Aga mis muutus?</v-card-title>
            <info-modal-container-wrapper>
                <h2 class="mt-4 mb-4">PRIA andmevahetus</h2>
                Peamenüüs on <strong>uus alamleht</strong> PRIA andmevahetuse jaoks.
                Sealt leiate võimaluse <strong>põldude, töökannete, kultuuride ja saagikuse andmete</strong> vahetamiseks PRIA'ga.
                Andmeid saadetakse PRIA'sse ainult juhul, kui kasutaja ise kinnitab andmete saatmise soovi.

                <h2 class="mt-12 mb-4">Toetatud töötüübid</h2>
                Nüüd on need Abimasina töötüübid, mida <strong>saab soovi korral PRIA e-põlluraamatusse saata</strong>, märgistatud sellise ikooniga: <br/>

                <v-row no-gutters class="mt-4 justify-center">
                <v-icon style="color: #656565" size="60">{{ICONS.PRIA_CHECK}}</v-icon><br/>
                </v-row>
                <template #footer>
                    <v-btn color="primary" elevation="0" class="mt-4" dark @click.native="showModal = false">Selge</v-btn>
                </template>
            </info-modal-container-wrapper>
        </v-card>
    </v-dialog>
</template>

<script>
import infoModalContainerWrapper from "@/components/infoModalContainerWrapper.vue";
import {ICONS} from "../constant/constants";


export default {
    components: {
        infoModalContainerWrapper,
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        priaJustConnected() {
            return this.$store.getters.priaJustConnected
        }
    },
    watch: {
        priaJustConnected() {
            if (this.priaJustConnected) {
                this.showModal = true
            }
        }
    },
    methods: {},
    created() {
    }
}
</script>