const _ = require('lodash');
const {calculateSeasonStart, calculateSeasonEnd} = require("./seasonInfo");

module.exports = {
    findCustomAreaYearlyAreaForYear: (yearlyAreas, yearValue) => {
        const year = parseInt(yearValue);
        const correctYearlyArea = yearlyAreas.find(yearlyArea => {
            return yearlyArea.year === year
        });
        if (correctYearlyArea) {
            return correctYearlyArea
        } else {
            let orderedYearlyAreas = _.sortBy(yearlyAreas, [areaInfo => areaInfo.year]); // Sorted 2000, 2001, 2002
            return _.findLast(orderedYearlyAreas, areaInfo => areaInfo.year <= year)
                || _.find(orderedYearlyAreas, areaInfo => areaInfo.year > year);
        }
    },
    findCustomAreaYearlyAreaForDate: (field, yearlyAreas, dateValue) => {
        if (typeof field === 'string' || field instanceof String) {
            return undefined
        }
        const date = new Date(dateValue);
        const fullYear = date.getFullYear();
        const seasonStart = calculateSeasonStart(field, fullYear);
        const seasonEnd = calculateSeasonEnd(field, fullYear);
        if (date >= seasonStart && date < seasonEnd) {
            return module.exports.findCustomAreaYearlyAreaForYear(yearlyAreas, fullYear);
        } else if (date >= seasonEnd) {
            return module.exports.findCustomAreaYearlyAreaForYear(yearlyAreas, fullYear+1);
        } else {
            return module.exports.findCustomAreaYearlyAreaForYear(yearlyAreas, fullYear-1);
        }
    },
};
