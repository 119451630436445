<template functional>
    <v-btn color="primary" class="elevation-0 px-2 mr-1 smallManageButton" @click.stop="props.clickAction" :title="props.title" :data-cy="props.dataCy">
        <i aria-hidden="true" class="v-icon material-icons theme--dark">edit</i>
    </v-btn>
</template>
<script>
    export default {
        props: {
            title: { type: String },
            clickAction: { type: Function },
            dataCy: String
        }
    }
</script>