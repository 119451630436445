<template>
    <v-row no-gutters>
        <v-col class="inspections">
            <v-row no-gutters>
                <v-col>
                    <transition-group name="inspection-row">
                        <v-row no-gutters v-for="(inspection, index) in inspections" :key="'inspection' + index"
                               :class="$vuetify.breakpoint.smAndDown ? 'boxed-content mb-3' : null" data-cy="inspection-row">
                            <v-col>
                                <v-row no-gutters>
                                    <v-col class="col-12 pr-2 pr-sm-0"
                                           :class="requisiteManagementMode ? 'col-md-4' : 'col-md-3'">
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-select
                                                    :prepend-icon="mdiRadioTower()"
                                                    :items="inspectionTypes"
                                                    v-model="inspection.inspectionType"
                                                    item-text="label"
                                                    item-value="value"
                                                    label="Seire tüüp"
                                                    data-cy="inspection-type"
                                                    :error-messages="inspectionErrors[index].inspectionType"
                                                ></v-select>
                                            </v-col>
                                            <div v-if="$vuetify.breakpoint.smAndDown"
                                                 class="ml-1 ml-sm-4 ml-md-4 align-self-center mt-n2"
                                                 style="width: 40px">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <div class="d-inline-block" v-on="on">
                                                            <delete-button title="Kustuta seire"
                                                                           :clickAction="() => deleteInspection(inspection)"
                                                                           data-cy="inspection-delete-button"/>
                                                        </div>
                                                    </template>
                                                    <span>{{ "Kustuta seire" }}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        :class="requisiteManagementMode ? 'col-12 col-md-8 pl-0 pl-md-4 pr-0 pr-md-4' : ((inspection.inspectionType === 'SUNDMUSED' || inspection.inspectionType === null) ? 'col-12 col-sm-6 col-md-9 pl-0 pl-md-4 pr-0 pr-sm-4 pr-md-4' : 'col-12 col-sm-6 col-md-5 pl-0 pl-md-4 pr-0 pr-sm-4 pr-md-4')">
                                        <v-combobox
                                            :items="augmentedInspectionTargets(inspection.inspectionType, searchTexts[index])"
                                            :prepend-icon="$vuetify.breakpoint.smAndDown ? mdiAltimeter() : null"
                                            v-model="inspection.target"
                                            ref="inspectionTargetInput"
                                            item-text="label"
                                            item-value="label"
                                            @update:search-input="(input) => searchUpdate(input, inspection, index)"
                                            auto-select-first
                                            clearable
                                            :return-object="false"
                                            label="Leid põllul"
                                            :hide-no-data="true"
                                            data-cy="inspection-target"
                                            :error-messages="inspectionErrors[index].target"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col v-if="!requisiteManagementMode"
                                           class="col-12 col-sm-6 col-md-4 pr-0 pr-md-4">
                                        <v-text-field v-if="inspection.inspectionType !== 'SUNDMUSED' && inspection.inspectionType !== null"
                                                      :prepend-icon="mdiSetCenterRight()"
                                                      v-model="inspection.amount"
                                                      type="number"
                                                      min="0"
                                                      :suffix="inspectionUnits(inspection.unit)"
                                                      data-cy="inspection-amount"
                                                      label="Kogus"
                                                      :error-messages="inspectionErrors[index].amount"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <div v-if="!$vuetify.breakpoint.smAndDown" class="ml-0 align-self-center mt-n2"
                                 style="width: 40px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <div class="d-inline-block" v-on="on">
                                            <delete-button title="Kustuta seire"
                                                           :clickAction="() => deleteInspection(inspection)"
                                                           data-cy="inspection-delete-button"/>
                                        </div>
                                    </template>
                                    <span>{{ "Kustuta seire" }}</span>
                                </v-tooltip>
                            </div>
                        </v-row>
                    </transition-group>
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary" dark large
                                   block
                                   class="ma-0"
                                   elevation="0"
                                   @click.native.stop="addInspection()"
                                   data-cy="inspection-add-button">
                                <v-icon>add</v-icon>
                                {{ requisiteManagementMode ? 'Lisa tüüpiline seire' : 'Lisa seire' }}
                                <v-icon class="ml-2">{{ mdiRadioTower() }}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </v-col>
    </v-row>
</template>

<script>

import {VBtn} from 'vuetify/lib'
import {mdiAltimeter, mdiRadioTower, mdiSetCenterRight} from "@mdi/js";
import DeleteButton from "@/components/deleteButton.vue";
import {
    inspectionDiseases,
    inspectionEvents,
    inspectionInsects,
    inspectionUnits,
    inspectionWeeds
} from "../../../backend/common/constants";
import {provideCloseDropdown} from "@/mixins/mixins";

export default {
    components: {DeleteButton, VBtn},
    props: {
        initialInspections: {
            type: Array,
            default: () => []
        },
        chemicalSubtype: {
            type: String,
            default: () => null
        },
        requisiteManagementMode: {
            type: Boolean,
            default: () => true
        },
        isCreatingNew: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            inspections: [],
            inspectionErrors: [],
            searchTexts: [],

        }
    },
    mixins: [provideCloseDropdown],
    computed: {
        inspectionTypes() {
            return this.$store.getters.getInspectionTypes
        },
        probableInspectionType() {
            if (this.chemicalSubtype) {
                if (this.chemicalSubtype === 'PMA_TKV_TOIME_LIIK_FUNGITSIID') {
                    return this.inspectionTypes.find(inspectionType => inspectionType.value === "TAIMEHAIGUSED").value;
                }
                if (this.chemicalSubtype === 'PMA_TKV_TOIME_LIIK_HERBITSIID') {
                    return this.inspectionTypes.find(inspectionType => inspectionType.value === "UMBROHUD").value;
                }
                if (this.chemicalSubtype === 'PMA_TKV_TOIME_LIIK_INSEKTITSIID') {
                    return this.inspectionTypes.find(inspectionType => inspectionType.value === "KAHJURID").value;
                }
            }
            return null
        },
        isInsideJobEventModal() {
            return !this.requisiteManagementMode
        }

    },
    methods: {
        mdiAltimeter() {
            return mdiAltimeter
        },
        mdiSetCenterRight() {
            return mdiSetCenterRight
        },
        mdiRadioTower() {
            return mdiRadioTower
        },
        addInspection() {
            this.inspections.push({
                inspectionType: this.probableInspectionType,
                target: null,
                amount: null,
                unit: null
            });
            this.inspectionErrors.push({});
            this.searchTexts.push({});
        },
        deleteInspection(inspectionToDelete) {
            this.inspections = this.inspections.filter(inspection => inspection !== inspectionToDelete);
        },
        inspectionTargets(inspectionType) {
            let inspectionTargets = [];
            if (inspectionType) {
                if (inspectionType === "TAIMEHAIGUSED") {
                    inspectionTargets = inspectionDiseases
                } else if (inspectionType === "KAHJURID") {
                    inspectionTargets = inspectionInsects
                } else if (inspectionType === "UMBROHUD") {
                    inspectionTargets = inspectionWeeds
                } else if (inspectionType === "SUNDMUSED") {
                    inspectionTargets = inspectionEvents
                }
            }
            return inspectionTargets;
        },
        augmentedInspectionTargets(inspectionType, searchText) {
            const inspectionTargets = this.inspectionTargets(inspectionType);
            if (searchText && (typeof searchText === 'string' || searchText instanceof String)) {
                return inspectionTargets.filter(target => target.label && target.label.toLowerCase().includes(searchText.toLowerCase()));
            } else {
                return inspectionTargets;
            }
        },
        inspectionUnits(units) {
            return inspectionUnits.find(unit => unit.value === units)?.label
        },
        validateFields(fullValidation = true) {
            let formHasErrors = null
            if (fullValidation) {
                this.inspectionErrors.forEach(errors => {
                    for (let key in errors) {
                        delete errors[key];
                    }
                });
            }

            this.inspections.forEach((inspection, index) => {
                const errors = {};

                if (!inspection.target || inspection.target === '') {
                    errors.target = ['Kohustuslik väli'];
                    formHasErrors = true
                }
                if ((!inspection.target || inspection.target === '') && inspection.inspectionType === null) {
                    errors.inspectionType = ['Kohustuslik väli'];
                    formHasErrors = true
                }

                if (this.isInsideJobEventModal &&
                    inspection.inspectionType !== 'SUNDMUSED' &&
                    inspection.inspectionType !== null &&
                    (!inspection.amount || inspection.amount === '' || inspection.amount === 0 || inspection.amount === "0" || inspection.amount < 0)
                ) {
                    if (inspection.amount < 0) {
                        errors.amount = ['Ei saa olla negatiivne'];
                    } else {
                        errors.amount = ['Kohustuslik väli'];
                    }
                    formHasErrors = true
                } else if (inspection.inspectionType && inspection.amount && inspection.target && inspection.target.lowerBound && inspection.target.upperBound) {
                    // Bounds can not be forced
                    // const target = this.getInspectionTargetInfo(inspection);
                    // if (target && inspection.amount < target.lowerBound) {
                    //     formHasErrors = true
                    //     errors.amount = ['Min kogus: ' + target.lowerBound];
                    // }
                    // if (target && inspection.amount > target.upperBound) {
                    //     formHasErrors = true
                    //     errors.amount = ['Max kogus: ' + target.upperBound];
                    // }
                }

                if (fullValidation) {
                    this.$set(this.inspectionErrors, index, errors);
                } else { // only remove errors that have been resolved
                    const currentErrors = this.inspectionErrors[index];
                    for (let currentErrorKey in currentErrors) {
                        if (!errors[currentErrorKey]) {
                            delete currentErrors[currentErrorKey];
                        }
                    }
                    this.$set(this.inspectionErrors, index, currentErrors);
                }
            });
            this.$emit('validate-inspections', formHasErrors)

        },
        getInspectionTargetInfo(inspection) {
            const targets = this.augmentedInspectionTargets(inspection.inspectionType);
            return targets.find(target => target.label === inspection.target);
        },
        automaticallyFillInspectionAmounts() {
            this.inspections.forEach((inspection) => {
                if (inspection.inspectionType && inspection.inspectionType !== 'SUNDMUSED' &&
                    (inspection.target !== null && inspection.target !== undefined && inspection.target !== '') &&
                    (inspection.amount === null || inspection.amount === undefined)) {
                    const target = this.getInspectionTargetInfo(inspection);
                    if (target && target.lowerBound && target.upperBound) {
                        const step = target.step ? target.step : 1;
                        const upperNormalized = target.upperBound / step
                        const lowerNormalized = target.lowerBound / step
                        inspection.amount = (Math.floor(Math.random() * (upperNormalized - lowerNormalized + 1)) + lowerNormalized) * step;
                        /*
                        var u = 100
                        var l = 30
                        var step = 1
                        var max = Number.MIN_SAFE_INTEGER
                        var min = Number.MAX_SAFE_INTEGER
                        for (let i = 0; i < 1000; i++) {
                            var upperNormalized = u / step
                            var lowerNormalized = l / step
                            const randomNumber = (Math.floor(Math.random() * (upperNormalized - lowerNormalized + 1)) + lowerNormalized) * step;
                            console.log(randomNumber);
                            max = Math.max(max, randomNumber);
                            min = Math.min(min, randomNumber);
                        }
                        console.log(min, max);
                        */
                    }
                }
            });
        },
        searchUpdate(value, inspection, index) {
            this.$set(inspection, 'target', value)
            this.$set(this.searchTexts, index, value)
        },
    },
    watch: {
        inspections: {
            handler: function (newVal) {
                this.inspections.filter(inspection => inspection.inspectionType).forEach((inspection) => {
                    if (inspection.inspectionType === "TAIMEHAIGUSED") {
                        inspection.unit = "PROTSENT_LEHEPINNAST";
                    } else if (inspection.inspectionType === "KAHJURID") {
                        inspection.unit = "TK_TAIME_KOHTA";
                    } else if (inspection.inspectionType === "UMBROHUD") {
                        inspection.unit = "TAIME_RUUT_MEETER";
                    } else if (inspection.inspectionType === "SUNDMUSED") {
                        inspection.unit = null;
                    }
                });

                this.inspections
                    .filter(inspection =>
                        inspection.target && inspection.target === inspection.target.toUpperCase() &&
                        this.inspectionTargets(inspection.inspectionType).find(target => target.value === inspection.target) === undefined
                    ) // Is internal target, but not from the given type
                    .forEach((inspection) => {
                        inspection.target = null;
                    });
                if (this.isInsideJobEventModal) {
                    this.automaticallyFillInspectionAmounts();
                }
                this.$emit('update:initialInspections', newVal);
                this.validateFields(false);
            },
            deep: true,
            immediate: true
        },
        initialInspections(newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && newVal) {
                this.inspections = [...newVal];
                this.inspectionErrors = this.inspections.map(() => ({}));
                this.searchTexts = this.inspections.map(() => ({}));
                if (this.isInsideJobEventModal) {
                    this.automaticallyFillInspectionAmounts();
                }
            }
        },
        chemicalSubtype(newVal, oldVal) {
            if (newVal !== oldVal && this.inspections && this.inspections.length === 1) {
                this.inspections[0].inspectionType = this.probableInspectionType;
            }
        },
    },
    created() {
        if (this.initialInspections) {
            this.inspections = [...this.initialInspections];
            this.inspectionErrors = this.inspections.map(() => ({}));
            this.searchTexts = this.inspections.map(() => ({}));
        }
        if (this.inspections && this.inspections.length === 0 && this.isInsideJobEventModal && this.isCreatingNew) {
            this.addInspection();
        }
    },
}
</script>