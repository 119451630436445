<template>
    <v-container style="padding: 0">
        <v-row no-gutters>
            <v-col class="pt-0 pt-sm-8">
                <v-row no-gutters :class="{'mt-0': $vuetify.breakpoint.smAndDown, 'mt-6': $vuetify.breakpoint.mdAndUp}">
                    <v-col cols="12" sm="10" offset-sm="1">
                        <v-alert border="bottom" color="orange darken-3" dark class="mt-2 mt-md-0 mx-1 mx-sm-0" v-if="waitingPayment">
                            Aastatellimuse arve ootab tasumist!
                        </v-alert>
<!--                        <v-alert border="bottom" color="blue darken-1" dark class="mt-2 mt-md-0 mx-1 mx-sm-0">-->
<!--                            KSM e-põlluraamatu kasutamise tõendi saab PRIA'le esitada "Teie konto" alamlehel ja alamsektsioonis "PRIA". <br/>-->
<!--                            <v-btn color="primary lighten-1 ml-0" :block="!$vuetify.breakpoint.smAndUp" @click="$router.push({name: 'account'})">Teie konto</v-btn>-->
<!--                        </v-alert>-->
                        <v-alert border="bottom" color="blue darken-1" dark class="mt-2 mt-md-0 mx-1 mx-sm-0" v-if="hasOldFertilizerWarning">
                            Väetistele on võimalik nüüdsest määrata täpsem <strong>tüüp</strong>!
                            Palun uuendage väetiste andmeid. <br/>
                        <v-btn color="primary lighten-1 ml-0" :block="!$vuetify.breakpoint.smAndUp" @click="$router.push({name: 'fertilizers'})">Väetisi uuendama</v-btn>
                        </v-alert>
                        <jobEventManagementModal :fieldSpecificJobs="false"/>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-8">
                    <v-col cols="12" sm="10" offset-sm="1">
                    <v-expansion-panels v-model="expandedPanels" accordion multiple>
                        <v-expansion-panel
                            v-for="(item,i) in accordionItems"
                            :key="i"
                            class="job-event-main-page-list"
                        >

                            <v-expansion-panel-header class="px-4 px-sm-0">
                                <template v-slot:actions>
                                    <v-btn block color="primary" outlined class="ma-0 mt-0" style="font-weight: 700">
                                        <v-icon color="primary" class="mr-1">
                                            $expand
                                        </v-icon>
                                        {{ item.title }}&nbsp;<span style="font-weight: 400"> - {{item.count}}</span>

                                    </v-btn>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="mb-6">
                                    <latelyAddedJobEvents :initialJobEvents="item.jobEvents"/>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="mt-6 mt-sm-12 mb-6 px-0" cols="12" sm="10" offset-sm="1">
                        <h1 class="px-2 px-sm-0">Viimati lisatud tööd</h1>
                        <latelyAddedJobEvents/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import jobEventManagementModal from "../jobEvent/jobEventManagementModal.vue"
    import latelyAddedJobEvents from "../components/latelyAddedJobEvents.vue"
    import {WARNING} from "../../../backend/common/constants";
    import {ACCOUNT_STATUS} from "@/constant/constants";

    export default {
        data() {
            return {
                expandedPanels: [0, 1]
            }
        },
        components: {
            jobEventManagementModal,
            latelyAddedJobEvents,
        },
        computed: {
            allPlannedJobEvents() {
                return this.$store.getters.getAllPlannedJobEvents;
            },
            allInProgressJobEvents() {
                return this.$store.getters.getAllInProgressJobEvents;
            },
            hasOldFertilizerWarning() {
                if (this.$store.getters.getWarnings) {
                    return this.$store.getters.getWarnings.some(warning => warning.type.key === WARNING.FERTILIZERS_MISSING_INFO.key);
                } else {
                    return false
                }
            },
            accordionItems() {
                let items = [];

                if (this.allInProgressJobEvents && this.allInProgressJobEvents.length > 0) {
                    items.push({
                        title: 'Tegemisel tööd',
                        count: this.allInProgressJobEvents.length,
                        jobEvents: this.allInProgressJobEvents
                    });
                }
                if (this.allPlannedJobEvents && this.allPlannedJobEvents.length > 0) {
                    items.push({
                        title: 'Planeeritud tööd',
                        count: this.allPlannedJobEvents.length,
                        jobEvents: this.allPlannedJobEvents,
                    });
                }
                return items;
            },
            waitingPayment() {
                let currentDate = this.$store.getters.getCurrentDate;
                let user = this.$store.getters.user;
                if (user === null) {
                    return false;
                }
                let accountStatus = this.$store.getters.accountStatus;
                const fiveDaysAgo = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
                const updatedAt = new Date(user.updatedAt);
                return this.$store.getters.hasLoadedStatus && accountStatus === ACCOUNT_STATUS.PROCESSING && updatedAt < fiveDaysAgo
            },
        },
        mounted() {
            window.scrollTo(0, 0);
        },
    }
</script>

<style>

</style>
