import {http} from "../../config/http.js"
import {computeWarnings} from "../../../../dist/controllers/warningsController";
import {ACCOUNT_STATUS} from "../../constant/constants.js"
import {INVOICE_BUFFER_AREA} from "../../../../backend/common/constants";

const _ = require('lodash');

let systemMessageTimeout;
const state = {
    demoMode: false,
    systemMessage: null,
    status: {},
    shouldShowOtherCategoryNotification: true,
    shouldShowAddRequisitesNotification: true,
    shouldShowUseMultipleFieldsNotification: true,
    env: process.env.NODE_ENV
};

const mutations = {
    SET_DEMO_MODE(state, demoModeStatus) {
        state.demoMode = demoModeStatus
    },
    SET_SYSTEM_MESSAGE(state, message) {
        state.systemMessage = {
            text: message.text,
            type: message.type,
            isTop: message.isTop ?? true,
        }
    },
    CLEAR_SYSTEM_MESSAGE(state) {
        state.systemMessage = null
    },
    SET_STATUS(state, status) {
        state.status = status
    },
    SET_SHOULD_SHOW_OTHER_CATEGORY_NOTIFICATION(state, status) {
        state.shouldShowOtherCategoryNotification = status
    },
    SET_SHOULD_SHOW_ADD_REQUISITES_NOTIFICATION(state, status) {
        state.shouldShowAddRequisitesNotification = status
    },
    SET_SHOULD_SHOW_USE_MULTIPLE_FIELDS_NOTIFICATION(state, status) {
        state.shouldShowUseMultipleFieldsNotification = status
    },
};

const actions = {
    doNotShowOtherCategoryNotification({commit}) {
        commit('SET_SHOULD_SHOW_OTHER_CATEGORY_NOTIFICATION', false)
    },
    doNotShowAddRequisitesNotification({commit}) {
        commit('SET_SHOULD_SHOW_ADD_REQUISITES_NOTIFICATION', false)
    },
    doNotShowUseMultipleFieldsNotification({commit}) {
        commit('SET_SHOULD_SHOW_USE_MULTIPLE_FIELDS_NOTIFICATION', false)
    },
    demoMode({commit}) {
        commit('SET_DEMO_MODE', true)
    },
    demoModeOff({commit}) {
        commit('SET_DEMO_MODE', false)
    },
    setSystemMessage({commit}, message) {
        commit('SET_SYSTEM_MESSAGE', message);
        clearTimeout(systemMessageTimeout);
        let timeout = 4000;
        if (message.timeout) {
            timeout = message.timeout;
        }
        systemMessageTimeout = setTimeout(() => {
            commit('CLEAR_SYSTEM_MESSAGE')
        }, timeout);
    },
    loadStatus({commit}) {
        http().get('/status')
            .then(r => r.data.data)
            .then(status => {
                commit('SET_STATUS', status)
            })
    },
    removeCustomFieldArea({}, customFieldAreaId) {
        return http().delete('/custom-areas/' + customFieldAreaId)
    },
    clearStatus({commit}) {
        commit('SET_STATUS', {})
    }
};

let lastSystemMessagePositionWasTop = true
let lastSystemMessageText = ""

const getters = {
    isDemoMode: state => {
        return state.demoMode
    },
    getSystemMessageText: state => {
        if (state.systemMessage) {
            lastSystemMessageText = state.systemMessage.text
            return state.systemMessage.text;
        } else {
            return lastSystemMessageText
        }
    },
    getSystemMessageIsTop: state => {
        if (state.systemMessage) {
            lastSystemMessagePositionWasTop = state.systemMessage.isTop
            return state.systemMessage.isTop;
        } else {
            return lastSystemMessagePositionWasTop
        }
    },
    getSystemMessageType: state => {
        if (state.systemMessage) {
            return state.systemMessage.type;
        }
        return "";
    },
    hasSystemMessage: state => {
        return !!state.systemMessage;
    },
    getCurrentFullYear: state => {
        if (state.status.currentDate) {
            return (new Date(state.status.currentDate)).getFullYear();
        }
        return (new Date()).getFullYear();
    },
    getCurrentDate: state => {
        if (state.status.currentDate) {
            return new Date(state.status.currentDate);
        }
        return new Date();
    },
    getTrialUntil: (state, getters) => {
        if (getters.user && getters.user.status && getters.user.status.trialUntil) {
            return getters.user.status.trialUntil;
        }
        return new Date();
    },
    accountStatus: (state, getters) => {
        let user = getters.user;
        if (user) {
            if (new Date(user.status.trialUntil) > getters.getCurrentDate) {
                return ACCOUNT_STATUS.TRIAL
            }
            if (getters.getPaidArea < getters.getRequestedArea) {
                return ACCOUNT_STATUS.PROCESSING;
            }
            const paidOrRequestedArea = Math.max(getters.getRequestedArea, getters.getPaidArea);
            const additionalArea = getters.linkedUsersActiveFieldTotalArea - paidOrRequestedArea;
            let hasOnlyVerySmallAreaAndNotPaid = paidOrRequestedArea === 0 && additionalArea > 0;
            if (paidOrRequestedArea < getters.linkedUsersActiveFieldTotalArea && (additionalArea > INVOICE_BUFFER_AREA || hasOnlyVerySmallAreaAndNotPaid)) { // At least 5 euros worth of area needs to be added
                return ACCOUNT_STATUS.NEEDS_CONFIRMATION;
            }
            return ACCOUNT_STATUS.ACTIVE;
        }
    },
    getWarningsForNewJobEvent: (state, getters) => fakeJobEvent => {
        let eventsWithRestrictions = state.status.eventsWithRestrictions;
        let existingEventsForField = _.filter(eventsWithRestrictions, event => event.fieldId === fakeJobEvent.fieldId &&
            (fakeJobEvent._id === undefined || fakeJobEvent._id === null ||fakeJobEvent._id !== event._id));
        let allJobEvents = _.concat(existingEventsForField, fakeJobEvent);
        let fields = getters.fields;
        return computeWarnings(allJobEvents, fields);
    },
    getShouldShowOtherCategoryNotification: (state) => {
        return state.shouldShowOtherCategoryNotification;
    },
    getShouldShowAddRequisitesNotification: (state) => {
        return state.shouldShowAddRequisitesNotification;
    },
    shouldShowUseMultipleFieldsNotification: (state) => {
        return state.shouldShowUseMultipleFieldsNotification;
    },
    getWarnings: (state) => {
        return state.status.warnings;
    },
    getLinkedDiners: (state) => {
        return state.status.linkedDiners;
    },
    getRequestedArea: (state) => {
        return state.status.requestedArea ?? 0;
    },
    getPaidArea: (state) => {
        return state.status.paidArea ?? 0;
    },
    getTotalActiveArea: (state) => {
        return state.status.totalActiveArea ?? 0;
    },
    hasPaidThisYear: (state) => {
        return state.status.hasPaidThisYear ?? false;
    },
    hasPaidEver: (state) => {
        return state.status.hasPaidEver ?? false;
    },
    getTotalOrderArea: (state) => {
        return state.status.totalOrderArea ?? 0;
    },
    getTotalOrderPrice: (state) => {
        return state.status.totalOrderPrice ?? 0;
    },
    getTotalOrderPriceVat: (state) => {
        return state.status.totalOrderPriceVat ?? 0;
    },
    getIsMinimumOrder: (state) => {
        return state.status.isMinimumOrder ?? false;
    },
    getIsMinimumOrderOnlyForThisAccount: (state) => {
        return state.status.partialOrder?.isMinimumOrder ?? false;
    },
    getAreaToOrderOnlyOnThisAccount: (state) => {
        return state.status.partialOrder?.area ?? 0;
    },
    getPriceForAreaToOrderOnlyOnThisAccount: (state) => {
        return state.status.partialOrder?.price ?? 0;
    },
    getPriceVatForAreaToOrderOnlyOnThisAccount: (state) => {
        return state.status.partialOrder?.priceVat ?? 0;
    },
    getShowPartialOrderOption: (state) => {
        return state.status.partialOrder ?? false;
    },
    getAllPlannedJobEvents: (state) => {
        return state.status.plannedJobEvents;
    },
    getAllInProgressJobEvents: (state) => {
        return state.status.inProgressJobEvents;
    },
    hasLoadedStatus: (state) => {
        return state.status.currentDate !== undefined;
    },
    isDevelopmentEnv(state) {
        return state.env === 'development';
    },
    isDevelopmentEnvOrDevelopmentAccount(state, getters) {
        return getters.isDevelopmentEnv || getters.isDevelopmentAccounts;
    },
    isDevelopmentAccounts(state, getters) {
        return getters.user &&
                (getters.user.email === 'joel@joel.ee' ||
                 getters.user.email === 'kaido@edenberg.ee' || getters.user.company.name === 'Luiga OÜ' || getters.user._id === '64bfb4e277b4325cdb9fb84b');

    },
    areaPercentageWithOfficialSeeds: (state) => year => {
        if (state.status && state.status.yearOverview) {
            const yearOverview = _.find(state.status.yearOverview, overview => overview.year === year);
            return yearOverview.areaPercentageWithOfficialSeeds;
        } else {
            return undefined;
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}