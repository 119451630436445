<template>
    <container-wrapper>
        <v-row no-gutters>
            <v-col v-if="hasNoWarning" class="text-h4 text-center" style="margin-top:20px">
                <v-icon color="primary" style="font-size: 100px">check</v-icon>
                <v-row no-gutters class="justify-center font-weight-bold" style="color:#61715c">Süsteem ei leidnud ühtegi hoiatust!</v-row>
                <v-row no-gutters class="text-subtitle-1 justify-center">
                    Hoiatuste puudumine ei välista, et põlluraamatus esineb rikkumisi.
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
            <v-card
                    class="mx-auto mb-4 pt-4"
                    light
                    width="100%"
                    outlined
                    v-for="(warning, index) in warnings"
                    style="background-color: #fcfcfc; max-width: 1100px"
                    :key="warning.targetId + warning.type.key+index"
            >
                <v-card-text>
                    <v-row no-gutters>
                        <v-col class="col-12 col-md-1 flex-grow-1 flex-md-grow-0 text-center mr-4 mt-n6">
                            <v-icon :color="warningIconColor(warning.type)" x-large class="mt-3" style="font-size:60px">{{warningIcon(warning.type)}}</v-icon>
                        </v-col>
                        <v-col>
                            <v-row no-gutters>
                                <v-col class="flex-grow-1 mb-4 mb-sm-0 pr-0 pr-md-6">
                                    <v-row no-gutters class="mt-1 mt-md-0 justify-center justify-md-start"
                                          :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom: 14px' : 'line-height: 33px;margin-bottom: 14px'">
                                        <h1>{{warning.title}}</h1>
                                    </v-row>
                                    <v-row no-gutters class="mt-1">
                                        <v-col class="pr-2 col-12 col-md-2" v-if="warning.date" style="min-width: 100px">
                                            <div class="fieldData">Kuupäev:</div>
                                            <span class="fieldDataValue" style="white-space: nowrap">{{warning.date | toDateString}}</span>
                                        </v-col>
                                        <v-col class="pr-2 col-12 col-md-4" v-if="fieldName(warning)">
                                            <div class="fieldData">Põld:</div>
                                            <div class="fieldDataValue">{{fieldName(warning)}}</div>
                                        </v-col>
                                        <v-col v-if="warning.event" :class="warning.requisite ? 'col-12 col-md-3' : 'col-12 col-md-6'">
                                            <div class="fieldData">Töökanne:</div>
                                            <div class="fieldDataValue">{{warning.event}}</div>
                                        </v-col>
                                        <v-col v-if="warning.requisite" :class="warning.event ? 'col-12 col-md-3' : 'col-12 col-md-6'">
                                            <div class="fieldData">Tarvik:</div>
                                            <div class="fieldDataValue">{{warning.requisite}}</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="fieldData">Seletus:</div>
                                            <div class="fieldDataValue" v-html="warning && warning.message"></div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="flex-grow-0" v-if="$vuetify.breakpoint.mdAndUp">
                                    <v-btn v-if="fieldName(warning)" color="primary" dark :to="{ name: 'jobEvents', params: yearAndField(warning.fieldId, warning.date)}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>
                                    <v-btn v-else-if="hasRequisiteName(warning)" color="primary" dark :to="{ name: 'chemicals'}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>

                                    <v-btn v-else-if="hasSeedName(warning)" color="primary" dark :to="{ name: 'seeds'}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>

                                    <v-btn v-else-if="hasWorker(warning)" color="primary" dark :to="{ name: 'workers'}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>
                                    <v-btn v-else-if="hasFertilizers(warning)" color="primary" dark :to="{ name: 'fertilizers'}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>
                                    <v-btn v-else-if="hasCustomAreas(warning)" color="primary" block dark :to="{ name: 'pollud', params: customFieldAreaParams}" style="margin-top:0">
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Täpsustama</v-btn>
                                    <v-btn v-else-if="hasStock(warning)" color="primary" dark :to="{ name: 'sockOverview'}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>
                                    <v-btn v-else color="primary" dark :to="{ name: 'pollud', params: currentYear}" style="margin-top:0" block>
                                        <v-icon style="margin-right:8px">edit</v-icon>
                                        Redigeerima</v-btn>
                                    <v-btn block color="primary" dark @click="ignoreWarning(warning)" style="margin-top:0">
                                        <v-icon style="margin-right:8px">delete_forever</v-icon>
                                        Kustuta hoiatus</v-btn>
                                </v-col>
                            </v-row>


                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown" class="justify-center justify-md-end mt-4 mb-6">
                        <v-btn v-if="fieldName(warning)" block color="primary" dark :to="{ name: 'jobEvents', params: yearAndField(warning.fieldId, warning.date)}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else-if="hasRequisiteName(warning)" color="primary" block dark :to="{ name: 'chemicals'}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else-if="hasSeedName(warning)" color="primary" block dark :to="{ name: 'seeds'}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else-if="hasWorker(warning)" color="primary" block dark :to="{ name: 'workers'}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else-if="hasFertilizers(warning)" color="primary" block dark :to="{ name: 'fertilizers'}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else-if="hasCustomAreas(warning)" color="primary" block dark :to="{ name: 'pollud', params: customFieldAreaParams}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Täpsustama2</v-btn>
                        <v-btn v-else-if="hasStock(warning)" color="primary" block dark :to="{ name: 'sockOverview'}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn v-else color="primary" block dark :to="{ name: 'pollud', params: currentYear}" style="margin-top:0">
                            <v-icon style="margin-right:8px">edit</v-icon>
                            Redigeerima</v-btn>
                        <v-btn block color="primary" dark @click="ignoreWarning(warning)" style="margin-top:0">
                            <v-icon style="margin-right:8px">delete_forever</v-icon>
                            Kustuta hoiatus</v-btn>
                    </v-row>
                </v-card-text>

            </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="align-center mt-12">
            <v-col>
                <v-row no-gutters class="justify-center text-h5 font-weight-regular" style="text-align:center; color:#61715c">Märkasite põlluraamatus mõnda probleemi, mida siin ei tuvastatud?</v-row>
                <v-row no-gutters class="justify-center text-subtitle-1 mt-4 mt-sm-0" style="text-align:center">
                    Andke teada ning ehk õnnestub lisada selle probleemi automaatne tuvastamine!
                </v-row>
                <v-row no-gutters class="justify-center">
                    <v-btn dark style="margin-top: 20px; margin-bottom:70px" color="primary" target="_blank"
                           @click="openFeedBackModal()"
                        >
                        <v-icon left>message</v-icon> Saada sõnum
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

    </container-wrapper>
</template>
<script>
import {findFieldYearInfoForDate} from "../../../dist/common/fieldYearInfo.js"
import {warningIcon, warningIconColor} from "../../../dist/common/warningVisuals.js"
import {EventBus} from '../event-bus.js';
import containerWrapper from "../components/containerWrapper";
import {http} from "@/config/http";
import {mapActions} from "vuex";
import {WARNING} from "../../../backend/common/constants";

export default {
        data: () => ({

        }),
        components: {
            containerWrapper,
        },
        computed: {
            warnings() {
                return this.$store.getters.getWarnings
            },
            hasNoWarning() {
                return !(this.$store.getters.getWarnings && this.$store.getters.getWarnings.length > 0)
            },
            currentYear() {
                let currentFullYear = this.$store.getters.getCurrentFullYear;
                return {year: currentFullYear};
            },
            customFieldAreaParams() {
                let currentFullYear = this.$store.getters.getCurrentFullYear;
                return {year: currentFullYear, subpage: 'customFieldAreas'};
            },
        },
        methods: {
            yearAndField(fieldId, date) {
                const field = this.$store.getters.field(fieldId);
                let relatedYearBySeasons;
                if (field) {
                    const yearInfo = findFieldYearInfoForDate(field, date);
                    relatedYearBySeasons = yearInfo.year;
                } else {
                    relatedYearBySeasons = this.$store.getters.getCurrentFullYear;
                }
                return {year: relatedYearBySeasons, id: fieldId};
            },
            fieldName(warning) {
                if (this.$store.getters.field(warning.fieldId)) {
                    const date = warning.date;
                    return findFieldYearInfoForDate(this.$store.getters.field(warning.fieldId), date).name
                } else {
                    return ""
                }
            },
            hasRequisiteName(warning) {
                return warning.requisite
            },
            hasSeedName(warning) {
                return warning.seed
            },
            hasWorker(warning) {
                return warning.workerId
            },
            hasFertilizers(warning) {
                return warning.type.key === WARNING.FERTILIZERS_MISSING_INFO.key
            },
            hasStock(warning) {
                return warning.type.key === WARNING.STOCK_FIXUP.key
            },
            hasCustomAreas(warning) {
                return warning.type.key === WARNING.CUSTOM_FIELD_AREAS_NEED_CONFIRMATION.key
            },
            warningIcon(type) {
                return warningIcon(type)
            },
            warningIconColor(type) {
                return warningIconColor(type)
            },
            openFeedBackModal() {
                EventBus.$emit('showFeedbackModal')
            },
            ...mapActions(["loadStatus", "setSystemMessage"]),
            ignoreWarning(warning) {
                this.$confirm('Kas olete <strong>tutvunud hoiatuse sisuga</strong> ning veendunud, et hoiatus <strong>ei ole Teie jaoks oluline</strong>? ' +
                    ' <br/>Hoiatused kaovad ära ka automaatselt, kui nende algpõhjus on lahenenud.', {
                    title: 'Hoiatuse kustutamine',
                    buttonTrueText: 'Jah, kustuta hoiatus'
                })
                    .then((result) => {
                        if (result) {
                            http().post('/user-interface/ignore-warning', warning)
                                .then(() => {
                                    this.setSystemMessage({
                                        text: "Hoiatus kustutatud!",
                                        timeout: 1700
                                    });

                                    this.loadStatus();
                                }).catch(() => {
                                    this.setSystemMessage({
                                        text: "Hoiatuse kustutamine ebaõnestus!",
                                        type: "ERROR"
                                    });
                                })
                        }
                    });
            },
        },
        mounted() {
            window.scrollTo(0,0);
        },
    }
</script>