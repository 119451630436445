<template>
<v-text-field
    v-model="editedArea"
    label=""
    class="darkestPlaceholder"
    single-line
    small
    dense
    outlined
    hide-details="auto"
    :error-messages="errorMessage"
    @blur="stopEditingIfNoChanges"
    @focus="isEditing = true"
    @input="onInputChange"
    data-cy="custom-area-input"
>
<!--    @keyup.enter="saveInvoiceId"-->
<!--  kui fookus kaob, aga on muudatusi, siis jäta nupud alles!  -->
    <template v-slot:append>
        <div class="mt-1 text-no-wrap" :style="(isEditing || needReconfirmation) ? null : 'opacity: 0.6'">{{areaChange > 0 ? "+" : null}}{{areaChange | toLocalRoundedNumber}} ha</div>
        <template v-if="(isEditing && areaHasBeenEdited) || needReconfirmation">
            <v-btn v-if="areaHasBeenEdited" outlined color="primary" elevation="0" small class="px-2 mr-1 ml-2 smallManageButton" style="margin-top: -2px !important;"
                   @click="() => undo()">
                <v-icon size="18">{{ mdiUndo() }}</v-icon>
            </v-btn>
            <v-btn color="primary" elevation="0" small class="px-2 mr-n1 smallManageButton" :class="editedArea === initialArea ? 'ml-2': null" style="margin-top: -2px !important;"
                   data-cy="confirm-custom-area-change-button"
                   @click="updateCustomArea">
                <v-icon size="18">{{ mdiCheckBold() }}</v-icon>
            </v-btn>
        </template>
    </template>
</v-text-field>
</template>

<style scoped>

</style>

<script>
import {mdiCheckBold, mdiUndo} from "@mdi/js";
import {http} from "@/config/http";

export default {
    props: {
        customFieldAreaId: {
            type: String,
        },
        fieldId: {
            type: String,
        },
        initialArea: {
            type: Number,
            default: 0,
        },
        fieldDefaultArea: {
            type: Number,
            default: 0,
        },
        needsToBeAdded: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String,
        },
        year: {
            type: Number
        }
    },
    data() {
        return {
            isEditing: false,
            editedArea: this.initialArea,
            hasUnsavedChanges: false,
        };
    },
    methods: {
        mdiUndo() {
            return mdiUndo
        },
        mdiCheckBold() {
            return mdiCheckBold
        },
        undo() {
            this.editedArea = this.initialArea;
            this.isEditing = false;
            this.hasUnsavedChanges = false;
            this.$emit('change', this, false);
        },
        stopEditingIfNoChanges() {
            if (this.editedArea === this.initialArea) {
                this.isEditing = false;
                this.hasUnsavedChanges = false;
                this.$emit('change', this, false);
            }
        },
        updateCustomArea() {
            http().put('/custom-areas/areas/' + this.customFieldAreaId, {year: this.year, fieldId: this.fieldId, area: this.editedArea, areaWhenItWasSaved: this.fieldDefaultArea})
                .then(r => r.data)
                .then(() => {
                    this.$store.dispatch('setSystemMessage', {text: "Pindala salvestatud!"});
                    this.hasUnsavedChanges = false;
                    this.$emit('change', this, false);
            }).catch(() => {
                this.$store.dispatch('setSystemMessage', {text: "Salvestamine ebaõnnestus!", type: "ERROR"})
            }).finally(() => {
                this.$store.dispatch('loadSettings');
            })
        },
        onInputChange() {
            const hasChanges = this.areaHasBeenEdited;
            if (hasChanges !== this.hasUnsavedChanges) {
                this.hasUnsavedChanges = hasChanges;
                this.$emit('change', this, hasChanges);
            }
        },
    },
    computed: {
        areaChange() {
            return this.editedArea - this.fieldDefaultArea;
        },
        needReconfirmation() {
            return this.status !== undefined;
        },
        errorMessage() {
            if (this.status === "MISSING") {
                return "Kontrollige ja kinnitage pindala!";
            } else if (this.status === "AREA_CHANGED") {
                return "Põllu tegelik pindala on oluliselt muutunud. Kontrollige ja kinnitage selle pindalatüübi pindala!";
            } else if (this.status === "POLYGON_MISSING") {
                return "Põllul puudub geomeetriline kontuur. Pindala ei saanud arvutada ja palun lisage see käsitsi!";
            } else if (this.status === "POLYGON_AREA_MISMATCH") {
                return "Põllu pindala ja kontuuri pindala erinevad olulisel määral. Pindala ei saanud arvutada ja palun lisage see käsitsi!";
            }
            return undefined;
        },
        areaHasBeenEdited() {
            return Number(this.editedArea || 0) !== Number(this.initialArea || 0)
        }
    },
    watch: {
        initialArea(update) {
            this.editedArea = update;
            this.hasUnsavedChanges = false;
            this.$emit('change', this, false);
        }
    }
};
</script>

<style scoped>
.invoice-id {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
</style>
